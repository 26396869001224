import * as React from "react"
import { Description } from "../components/Description";
import { Layout } from "../components/Layout";
import { PageTitle } from "../components/PageTitle";

export const Head = () => <title>Lost in the fog | Worldwide Lighthouses</title>;

const NotFoundPage = () => {
  return (
    <Layout>
      <PageTitle title="404 - Lost in the fog" />
      <Description>
        Unfortunately we could not find the page you were looking for.
      </Description>
    </Layout>
  )
}

export default NotFoundPage
